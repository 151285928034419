@charset "UTF-8";

/* line 22, ../../../../../../Library/Ruby/Gems/2.0.0/gems/compass-core-1.0.3/stylesheets/compass/reset/_utilities.scss */

html {
    line-height : 1;
}

/* line 24, ../../../../../../Library/Ruby/Gems/2.0.0/gems/compass-core-1.0.3/stylesheets/compass/reset/_utilities.scss */

ol, ul {
    list-style : none;
}

/* line 26, ../../../../../../Library/Ruby/Gems/2.0.0/gems/compass-core-1.0.3/stylesheets/compass/reset/_utilities.scss */

table {
    border-collapse : collapse;
    border-spacing  : 0;
}

/* line 28, ../../../../../../Library/Ruby/Gems/2.0.0/gems/compass-core-1.0.3/stylesheets/compass/reset/_utilities.scss */

caption, th, td {
    text-align     : left;
    font-weight    : normal;
    vertical-align : middle;
}

/* line 30, ../../../../../../Library/Ruby/Gems/2.0.0/gems/compass-core-1.0.3/stylesheets/compass/reset/_utilities.scss */

q, blockquote {
    quotes : none;
}

/* line 103, ../../../../../../Library/Ruby/Gems/2.0.0/gems/compass-core-1.0.3/stylesheets/compass/reset/_utilities.scss */

q {
    &:before, &:after {
        content : "";
        content : none;
    }
}

blockquote {
    &:before, &:after {
        content : "";
        content : none;
    }
}

/* line 32, ../../../../../../Library/Ruby/Gems/2.0.0/gems/compass-core-1.0.3/stylesheets/compass/reset/_utilities.scss */

a img {
    border : none;
}

/*
オリジナルのユーティリティを格納するFile
*/
/* line 5, ../sass/_util.scss */

* {
    -moz-box-sizing    : border-box;
    -webkit-box-sizing : border-box;
    -o-box-sizing      : border-box;
    -ms-box-sizing     : border-box;
}

/* line 12, ../sass/_util.scss */

.fl {
    float : left;
}

/* line 16, ../sass/_util.scss */

.fr {
    float : right;
}

/*.mc{
    margin: auto !important;
}*/
/*.hidden{
    visibility: hidden;
}*/
/* line 28, ../sass/_util.scss */

.mr52 {
    margin-right : 52px;
}

/* line 32, ../sass/_util.scss */

.mt10 {
    margin-top : 10px;
}

/* line 36, ../sass/_util.scss */

.mt20 {
    margin-top : 20px;
}

/* line 40, ../sass/_util.scss */

.mt30 {
    margin-top : 30px;
}

/* line 44, ../sass/_util.scss */

.mb0 {
    margin-bottom : 0 !important;
}

/* line 48, ../sass/_util.scss */

.mb10 {
    margin-bottom : 10px !important;
}

/* line 52, ../sass/_util.scss */

.mb15 {
    margin-bottom : 15px !important;
}

/* line 56, ../sass/_util.scss */

.mb20 {
    margin-bottom : 20px !important;
}

/* line 60, ../sass/_util.scss */

.mb30 {
    margin-bottom : 30px !important;
}

/* line 64, ../sass/_util.scss */

.mb40 {
    margin-bottom : 40px !important;
}

/* line 68, ../sass/_util.scss */

.mb45 {
    margin-bottom : 45px !important;
}

/* line 72, ../sass/_util.scss */

.mb50 {
    margin-bottom : 50px !important;
}

/* line 76, ../sass/_util.scss */

.mb60 {
    margin-bottom : 60px !important;
}

/* line 80, ../sass/_util.scss */

.mb70 {
    margin-bottom : 70px !important;
}

/* line 84, ../sass/_util.scss */

.mb75 {
    margin-bottom : 75px !important;
}

/* line 88, ../sass/_util.scss */

.mb90 {
    margin-bottom : 90px !important;
}

/* line 92, ../sass/_util.scss */

.mb100 {
    margin-bottom : 100px !important;
}

/* line 96, ../sass/_util.scss */

.mb180 {
    margin-bottom : 180px !important;
}

/* line 100, ../sass/_util.scss */

.mc {
    margin-left  : auto;
    margin-right : auto;
}

/* line 105, ../sass/_util.scss */

.mt15 {
    margin-top : 15px;
}

/* line 109, ../sass/_util.scss */

.mr20 {
    margin-right : 20px;
}

/* line 113, ../sass/_util.scss */

.mb65 {
    margin-bottom : 65px !important;
}

/* line 117, ../sass/_util.scss */

.sprite {
    display     : block;
    overflow    : hidden;
    text-indent : -9999px;
}

/*
他で使用する変数を格納するFile
状態_要素
*/
/* ボックシャドウ */
/* opacity */
/* transition */
/*
他で使用する変数を格納するFile
状態_要素
*/
@-webkit-keyframes fuwafuwa {
    0% {
        -webkit-transform : translate(0, 0);
    }

    50% {
        -webkit-transform : translate(0, -10px);
    }

    100% {
        -webkit-transform : translate(0, 0);
    }
}

@-moz-keyframes fuwafuwa {
    0% {
        -moz-transform : translate(0, 0);
    }

    50% {
        -moz-transform : translate(0, -10px);
    }

    100% {
        -moz-transform : translate(0, 0);
    }
}

@keyframes fadeInPage {
    0% {
        opacity : 0;
    }

    100% {
        opacity : 1;
    }
}

@-webkit-keyframes fadeInPage {
    0% {
        opacity : 0;
    }

    100% {
        opacity : 1;
    }
}

@keyframes animationTxt1 {
    0% {
        opacity           : 0;
        -webkit-transform : translate(0, 50px);
    }

    100% {
        opacity           : 1;
        -webkit-transform : translate(0, 0);
    }
}

@-webkit-keyframes animationTxt1 {
    0% {
        opacity           : 0;
        -webkit-transform : translate(0, 50px);
    }

    100% {
        opacity           : 1;
        -webkit-transform : translate(0, 0);
    }
}

@keyframes animationTxt2 {
    0% {
        opacity           : 0;
        -webkit-transform : translate(0, 162px);
    }

    100% {
        opacity           : 1;
        -webkit-transform : translate(0, 112px);
    }
}

@-webkit-keyframes animationTxt2 {
    0% {
        opacity           : 0;
        -webkit-transform : translate(0, 162px);
    }

    100% {
        opacity           : 1;
        -webkit-transform : translate(0, 112px);
    }
}

/* SP用アニメーション */
@keyframes SpAnimationTxt1 {
    0% {
        opacity           : 0;
        -webkit-transform : translate(0, 40px);
    }

    100% {
        opacity           : 1;
        -webkit-transform : translate(0, 0);
    }
}

@-webkit-keyframes SpAnimationTxt1 {
    0% {
        opacity           : 0;
        -webkit-transform : translate(0, 40px);
    }

    100% {
        opacity           : 1;
        -webkit-transform : translate(0, 0);
    }
}

@keyframes SpAnimationTxt2 {
    0% {
        opacity           : 0;
        -webkit-transform : translate(0, 86px);
    }

    100% {
        opacity           : 1;
        -webkit-transform : translate(0, 46px);
    }
}

@-webkit-keyframes SpAnimationTxt2 {
    0% {
        opacity           : 0;
        -webkit-transform : translate(0, 86px);
    }

    100% {
        opacity           : 1;
        -webkit-transform : translate(0, 46px);
    }
}

/*
リセット(normalize)、全体的な見出し、カラーなどを格納するFile
*/
/* line 6, ../sass/_common.scss */

* {
    margin  : 0;
    padding : 0;
    border  : 0;
}

/* line 12, ../sass/_common.scss */

ul, li, ol {
    list-style : none;
}

/* line 16, ../sass/_common.scss */

ul, li, ol {
    margin  : 0;
    padding : 0;
}

/*	clearfix
================================================== */
/* line 25, ../sass/_common.scss */

.cf {
    &:before {
        content : " ";
        display : table;
    }
    &:after {
        content : " ";
        display : table;
        clear   : both;
    }
    *zoom : 1;
}

/* line 26, ../sass/_common.scss */

/* line 27, ../sass/_common.scss */

/* line 29, ../sass/_common.scss */

body {
                /*animation: fadeInPage 1s ease 1s 1 normal;
              -webkit-animation: fadeInPage 1s ease 1s 1 normal;
              animation-fill-mode:forwards;
              opacity: 0;*/
    margin      : 0;
    font-family : 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}

/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
/* line 76, ../sass/_common.scss */

audio, canvas, progress, video {
    display        : inline-block;
                   /* 1 */
    vertical-align : baseline;
                   /* 2 */
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
/* line 89, ../sass/_common.scss */

audio:not([controls]) {
    display : none;
    height  : 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
 */
/* line 99, ../sass/_common.scss */

[hidden], template {
    display : none;
}

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
/* line 111, ../sass/_common.scss */

a {
    background-color : transparent;
    &:active, &:hover {
        outline : 0;
    }
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
/* line 119, ../sass/_common.scss */

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
/* line 131, ../sass/_common.scss */

abbr[title] {
    border-bottom : 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
/* line 139, ../sass/_common.scss */

b, strong {
    font-weight : bold;
}

/**
 * Address styling not present in Safari and Chrome.
 */
/* line 148, ../sass/_common.scss */

dfn {
    font-style : italic;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
/* line 157, ../sass/_common.scss */

h1 {
    font-size : 2em;
    margin    : 0.67em 0;
}

/**
 * Address styling not present in IE 8/9.
 */
/* line 166, ../sass/_common.scss */

mark {
    background : #ffff00;
    color      : #000000;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */
/* line 175, ../sass/_common.scss */

small {
    font-size : 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
/* line 183, ../sass/_common.scss */

sub {
    font-size      : 75%;
    line-height    : 0;
    position       : relative;
    vertical-align : baseline;
}

sup {
    font-size      : 75%;
    line-height    : 0;
    position       : relative;
    vertical-align : baseline;
    top            : -0.5em;
}

/* line 191, ../sass/_common.scss */

/* line 195, ../sass/_common.scss */

sub {
    bottom : -0.25em;
}

/* Embedded content
   ========================================================================== */
/**
 * Remove border when inside `a` element in IE 8/9/10.
 */
/* line 206, ../sass/_common.scss */

img {
    border : 0;
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
/* line 214, ../sass/_common.scss */

svg:not(:root) {
    overflow : hidden;
}

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
/* line 225, ../sass/_common.scss */

figure {
    margin : 1em 40px;
}

/**
 * Address differences between Firefox and other browsers.
 */
/* line 233, ../sass/_common.scss */

hr {
    -moz-box-sizing : content-box;
    box-sizing      : content-box;
    height          : 0;
}

/**
 * Contain overflow in all browsers.
 */
/* line 243, ../sass/_common.scss */

pre {
    overflow : auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 */
/* line 251, ../sass/_common.scss */

code, kbd, pre, samp {
    font-family : monospace, monospace;
    font-size   : 1em;
}

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
/* line 274, ../sass/_common.scss */

button, input, optgroup, select, textarea {
    color  : inherit;
           /* 1 */
    font   : inherit;
           /* 2 */
    margin : 0;
           /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
/* line 288, ../sass/_common.scss */

button {
    overflow       : visible;
    text-transform : none;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
/* line 299, ../sass/_common.scss */

select {
    text-transform : none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
/* line 312, ../sass/_common.scss */

button, html input[type="button"] {
    -webkit-appearance : button;
                       /* 2 */
    cursor             : pointer;
                       /* 3 */
}

input {
    &[type="reset"], &[type="submit"] {
        -webkit-appearance : button;
                           /* 2 */
        cursor             : pointer;
                           /* 3 */
    }
}

/**
 * Re-set default cursor for disabled elements.
 */
/* line 324, ../sass/_common.scss */

button[disabled], html input[disabled] {
    cursor : default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
/* line 333, ../sass/_common.scss */

button::-moz-focus-inner {
    border  : 0;
    padding : 0;
}

input {
    &::-moz-focus-inner {
        border  : 0;
        padding : 0;
    }
    line-height : normal;
    &[type="checkbox"], &[type="radio"] {
        box-sizing : border-box;
                   /* 1 */
        padding    : 0;
                   /* 2 */
    }
    &[type="number"] {
        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
            height : auto;
        }
    }
    &[type="search"] {
        -webkit-appearance : textfield;
                           /* 1 */
        -moz-box-sizing    : content-box;
        -webkit-box-sizing : content-box;
                           /* 2 */
        box-sizing         : content-box;
        &::-webkit-search-cancel-button, &::-webkit-search-decoration {
            -webkit-appearance : none;
        }
    }
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
/* line 344, ../sass/_common.scss */

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
/* line 356, ../sass/_common.scss */

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
/* line 368, ../sass/_common.scss */

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */
/* line 379, ../sass/_common.scss */

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
/* line 392, ../sass/_common.scss */

/**
 * Define consistent border, margin, and padding.
 */
/* line 401, ../sass/_common.scss */

fieldset {
    border  : 1px solid #c0c0c0;
    margin  : 0 2px;
    padding : 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
/* line 412, ../sass/_common.scss */

legend {
    border  : 0;
            /* 1 */
    padding : 0;
            /* 2 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
/* line 421, ../sass/_common.scss */

textarea {
    overflow : auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
/* line 430, ../sass/_common.scss */

optgroup {
    font-weight : bold;
}

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
/* line 441, ../sass/_common.scss */

table {
    border-collapse : collapse;
    border-spacing  : 0;
}

/* line 446, ../sass/_common.scss */

td, th {
    padding : 0;
}

/* line 1, ../sass/_layout.scss */

#article {
    //    padding-top : 57px;
}

/* line 5, ../sass/_layout.scss */

#pageHeader {
    width      : 100%;
    height     : 57px;
    position   : fixed;
    background : #ffffff;
    top        : 0;
    margin     : 0;
    z-index    : 990;
    .logo-link {
        width              : 94px;
        height             : 114px;
        position           : absolute;
        display            : block;
        top                : 28px;
        left               : 48px;
        -moz-transition    : all 0.15s;
        -o-transition      : all 0.15s;
        -webkit-transition : all 0.15s;
        transition         : all 0.15s;
        z-index            : 100;
        .logo {
            background      : url($path_logo) no-repeat;
            background-size : cover;
            width           : 94px;
            height          : 114px;
            margin          : 0;
        }
        &:hover {
            filter  : progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
            opacity : 0.8;
        }
    }
    #glb_nav {
        position  : absolute;
        top       : 23px;
        right     : 54px;
        font-size : 13px;
        li {
            float    : left;
            margin   : 0 45px 0 0;
            position : relative;
            &:last-child {
                margin : 0;
            }
            &.d_trig {
                cursor  : default;
                height  : 34px;
                display : block;
            }
            a {
                color              : #1d1d1d;
                text-decoration    : none;
                -moz-transition    : all 0.15s;
                -o-transition      : all 0.15s;
                -webkit-transition : all 0.15s;
                transition         : all 0.15s;
                &:hover {
                    color : #e4007f;
                }
            }
            .sml_nav {
                background : rgba(228, 0, 127, 0.6);
                position   : absolute;
                top        : 34px;
                width      : 156px;
                display    : none;
                li {
                    float         : none;
                    margin        : 0;
                    border-bottom : 1px solid #ffffff;
                    &:last-child {
                        border-bottom : none;
                    }
                    a {
                        color           : #ffffff;
                        padding         : 10px 14px;
                        text-decoration : none;
                        display         : block;
                    }
                }
            }
        }
    }
}

/* line 13, ../sass/_layout.scss */

/* line 22, ../sass/_layout.scss */

/* line 29, ../sass/_layout.scss */

/* line 33, ../sass/_layout.scss */

/* line 38, ../sass/_layout.scss */

/* line 42, ../sass/_layout.scss */

/* line 45, ../sass/_layout.scss */

/* line 51, ../sass/_layout.scss */

/* line 55, ../sass/_layout.scss */

/* line 59, ../sass/_layout.scss */

/* line 66, ../sass/_layout.scss */

/* line 70, ../sass/_layout.scss */

/* line 73, ../sass/_layout.scss */

/* line 85, ../sass/_layout.scss */

.pageVisual .breadcrumb {
    position : absolute;
    bottom   : 20px;
}

/* line 90, ../sass/_layout.scss */

.breadcrumb {
    width     : calc(100% - 48px);
    min-width : 1080px;
    position  : relative;
    left      : 48px;
    font-size : 11px;
    display   : block;
    ol {
        color : #000000;
        &.white {
            color : #ffffff;
            li {
                background : url(/asset/image/common/breadcrumb_w.png) no-repeat right center;
                a {
                    color : #ffffff;
                    &:after {
                        content    : '';
                        position   : absolute;
                        width      : 100%;
                        height     : 1px;
                        bottom     : 0;
                        background : #ffffff;
                        left       : 0;
                    }
                    &:hover:after {
                        width   : 0;
                        display : none;
                    }
                }
            }
        }
        li {
            float        : left;
            padding      : 0 14px 0 0;
            background   : url(/asset/image/common/breadcrumb.png) no-repeat right center;
            margin-right : 9px;
            &:last-child {
                padding    : 0;
                background : transparent;
            }
            a {
                color           : #000000;
                position        : relative;
                text-decoration : none;
                padding-bottom  : 6px;
                &:after {
                    content    : '';
                    position   : absolute;
                    width      : 100%;
                    height     : 1px;
                    bottom     : 0;
                    background : #e4007f;
                    left       : 0;
                }
                &:hover:after {
                    width   : 0;
                    display : none;
                }
            }
        }
    }
}

/* line 97, ../sass/_layout.scss */

/* line 99, ../sass/_layout.scss */

/* line 101, ../sass/_layout.scss */

/* line 103, ../sass/_layout.scss */

/* line 105, ../sass/_layout.scss */

/* line 115, ../sass/_layout.scss */

/* line 123, ../sass/_layout.scss */

/* line 128, ../sass/_layout.scss */

/* line 132, ../sass/_layout.scss */

/* line 137, ../sass/_layout.scss */

/* line 147, ../sass/_layout.scss */

/* line 156, ../sass/_layout.scss */

#footer {
    width      : 100%;
    min-width  : 1080px;
    display    : block;
    position   : relative;
    background : #ffffff;
    .foot_contact {
                                //        background: url(../images/common/foot-contact_visual.jpg) no-repeat;
        background-size         : cover;
        width                   : 100%;
        min-width               : 1080px;
        height                  : 380px;
        display                 : -webkit-flex;
                                /* Safari */
        display                 : flex;
                                /* 2 */
        -webkit-justify-content : center;
                                /* Safari */
        justify-content         : center;
                                /* 3 */
        -webkit-align-items     : center;
                                /* Safari */
        align-items             : center;
        margin                  : auto auto 75px;
        .inner {
            position   : relative;
            text-align : center;
            .f_c_ttl {
                                //                background: url(../images/common/foot_contact.png) no-repeat;
                background-size : cover;
                width           : 180px;
                height          : 40px;
                display         : block;
                margin          : auto auto 26px;
            }
            .btn {
                width              : 410px;
                height             : 80px;
                background         : #e4007f;
                border-radius      : 15px;
                display            : block;
                position           : relative;
                padding            : 25px 0;
                margin             : auto auto 30px;
                -moz-transition    : all 0.15s;
                -o-transition      : all 0.15s;
                -webkit-transition : all 0.15s;
                transition         : all 0.15s;
                span {
                    display         : block;
                                    //                    background: url(../images/common/foot_contact_txt.png) no-repeat;
                    background-size : cover;
                    width           : 372px;
                    height          : 30px;
                    margin          : auto;
                }
                &:hover {
                    opacity : .8;
                }
            }
            p {
                font-weight : bold;
                font-size   : 16px;
                line-height : 1;
                color       : #ffffff;
            }
        }
    }
    .foot_main {
        width   : 990px;
        display : block;
        margin  : auto auto;
        .bnr_list_1 {
            text-align : center;
            margin     : auto auto 30px;
            li {
                width   : 225px;
                height  : 140px;
                display : inline-block;
                margin  : 0 12px;
                &:first-child {
                    margin : 0 12px 0 0;
                }
                &:last-child {
                    margin : 0 0 0 12px;
                }
                a {
                    display : block;
                    width   : 225px;
                    height  : 140px;
                    &.bnr1 {
                        //                        background: url(../images/common/foot_bnr_1.jpg) no-repeat;
                    }
                    &.bnr2 {
                        //                        background: url(../images/common/foot_bnr_2.jpg) no-repeat;
                    }
                    &.bnr3 {
                        //                      background: url(../images/common/foot_bnr_3.jpg) no-repeat;
                    }
                    &.bnr4 {
                        //                        background: url(../images/common/foot_bnr_4.jpg) no-repeat;
                    }
                }
            }
        }
        .bnr_list_2 {
            text-align : center;
            li {
                width   : 225px;
                height  : 110px;
                display : inline-block;
                margin  : 0 12px;
                &:first-child {
                    margin : 0 12px 0 0;
                }
                &:last-child {
                    margin : 0 0 0 12px;
                }
                a {
                    display : block;
                    width   : 225px;
                    height  : 110px;
                    &.bnr5 {
                        //                        background: url(../images/common/foot_bnr_5.jpg) no-repeat;
                    }
                    &.bnr6 {
                        //                        background: url(../images/common/foot_bnr_6.jpg) no-repeat;
                    }
                    &.bnr7 {
                        //                        background: url(../images/common/foot_bnr_7.jpg) no-repeat;
                    }
                }
            }
        }
        .main_nav {
            margin         : 70px auto 30px;
            padding-top    : 70px;
            padding-bottom : 88px;
            border-top     : 1px solid #e9e9e9;
            border-bottom  : 1px solid #e9e9e9;
            position       : relative;
            .foot_nav {
                font-size : 12px;
                li {
                    float  : left;
                    margin : 0 40px 0 0;
                    a {
                        color           : #1d1d1d;
                        text-decoration : none;
                    }
                    .sml_nav {
                        margin-top : 9px;
                        li {
                            line-height : 2em;
                            float       : none;
                            margin      : 0 10px 0 0;
                            a {
                                color : #8c8c8c;
                            }
                        }
                    }
                }
            }
            .foot_sns {
                position : absolute;
                top      : 70px;
                right    : 0;
                li {
                    width   : 48px;
                    height  : 48px;
                    float   : left;
                    display : block;
                    margin  : 0 20px 0 0;
                    &:last-child {
                        margin : 0;
                    }
                    a {
                        width  : 48px;
                        height : 48px;
                        &.fb {
                            //                            background: url(../images/common/foot_fb.png) no-repeat;
                        }
                        &.yt {
                            //                            background: url(../images/common/foot_yt.png) no-repeat;
                        }
                    }
                }
            }
        }
        .sub_nav {
            padding-bottom : 88px;
            ul {
                font-size  : 12px;
                text-align : center;
                display    : block;
                li {
                    padding  : 0 16px 0 15px;
                    position : relative;
                    display  : inline-block;
                    margin   : 0;
                    &:last-child:after {
                        content : '';
                        display : none;
                    }
                    &:after {
                        content    : '';
                        position   : absolute;
                        right      : 0;
                        top        : 0;
                        bottom     : 0;
                        margin     : auto;
                        height     : 95%;
                        background : #cccccc;
                        width      : 1px;
                    }
                    a {
                        display         : block;
                        color           : #1d1d1d;
                        text-decoration : none;
                    }
                }
            }
        }
    }
    .foot_bottom {
        background : #000000;
        height     : 56px;
        display    : block;
        width      : 100%;
        min-width  : 1080px;
        position   : relative;
        .copyright {
            width    : 274px;
            height   : 14px;
                     //            background: url(../images/common/copyright.png) no-repeat;
            margin   : auto;
            position : absolute;
            top      : 0;
            right    : 0;
            bottom   : 0;
            left     : 0;
        }
    }
}

/* line 162, ../sass/_layout.scss */

/* line 175, ../sass/_layout.scss */

/* line 178, ../sass/_layout.scss */

/* line 186, ../sass/_layout.scss */

/* line 197, ../sass/_layout.scss */

/* line 205, ../sass/_layout.scss */

/* line 209, ../sass/_layout.scss */

/* line 217, ../sass/_layout.scss */

/* line 221, ../sass/_layout.scss */

/* line 224, ../sass/_layout.scss */

/* line 229, ../sass/_layout.scss */

/* line 232, ../sass/_layout.scss */

/* line 235, ../sass/_layout.scss */

/* line 239, ../sass/_layout.scss */

/* line 242, ../sass/_layout.scss */

/* line 245, ../sass/_layout.scss */

/* line 248, ../sass/_layout.scss */

/* line 254, ../sass/_layout.scss */

/* line 256, ../sass/_layout.scss */

/* line 261, ../sass/_layout.scss */

/* line 264, ../sass/_layout.scss */

/* line 267, ../sass/_layout.scss */

/* line 271, ../sass/_layout.scss */

/* line 274, ../sass/_layout.scss */

/* line 277, ../sass/_layout.scss */

/* line 283, ../sass/_layout.scss */

/* line 290, ../sass/_layout.scss */

/* line 292, ../sass/_layout.scss */

/* line 295, ../sass/_layout.scss */

/* line 299, ../sass/_layout.scss */

/* line 301, ../sass/_layout.scss */

/* line 305, ../sass/_layout.scss */

/* line 312, ../sass/_layout.scss */

/* line 316, ../sass/_layout.scss */

/* line 322, ../sass/_layout.scss */

/* line 325, ../sass/_layout.scss */

/* line 328, ../sass/_layout.scss */

/* line 331, ../sass/_layout.scss */

/* line 338, ../sass/_layout.scss */

/* line 340, ../sass/_layout.scss */

/* line 344, ../sass/_layout.scss */

/* line 350, ../sass/_layout.scss */

/* line 355, ../sass/_layout.scss */

/* line 366, ../sass/_layout.scss */

/* line 375, ../sass/_layout.scss */

/* line 382, ../sass/_layout.scss */

/* =======================================
    ClearFixElements
======================================= */
/* line 399, ../sass/_layout.scss */

.photoArea {
    ul {
        &:after {
            content    : ".";
            height     : 0;
            clear      : both;
            display    : block;
            visibility : hidden;
        }
        display  : inline-block;
        overflow : hidden;
    }
    height : 460px;
    margin : 0 0 58px;
}

/* line 407, ../sass/_layout.scss */

/* line 412, ../sass/_layout.scss */

/* line 417, ../sass/_layout.scss */

.photoList {
    height   : 460px;
    float    : left;
    display  : inline;
    overflow : hidden;
    li {
        width    : 690px !important;
        height   : 460px !important;
        margin   : 0 10px;
        float    : left;
        display  : inline;
        overflow : hidden;
        &.photo1 {
                            //            background: url(../images/pages/about/photo/concept-photo-0.jpg) no-repeat;
            background-size : cover;
        }
        &.photo2 {
                            //            background: url(../images/pages/about/photo/concept-photo-1.jpg) no-repeat;
            background-size : cover;
        }
        &.photo3 {
                            //            background: url(../images/pages/about/photo/concept-photo-2.jpg) no-repeat;
            background-size : cover;
        }
    }
}

/* line 9, ../sass/_page.scss */

.privacyPage {
    .pageTtl {
               //        background: url(../images/pages/privacy/ttl.png) no-repeat;
        width  : 452px;
        height : 102px;
        margin : 135px auto 45px;
    }
    p {
        font-size   : 12px;
        line-height : 2em;
        color       : #000000;
    }
    .blk-1 {
        padding : 53px 0;
        p {
            text-align : center;
        }
    }
    .blk-2 {
        padding-bottom : 100px;
        .privacy_list {
            width  : 990px;
            margin : auto;
            li {
                padding       : 40px 0 35px;
                border-bottom : 1px solid #c7c7c7;
                h2 {
                    font-size   : 20px;
                    margin      : 0 0 25px;
                    line-height : 1;
                    font-weight : bold;
                    span {
                        color        : #e4007f;
                        margin-right : 4px;
                    }
                }
            }
        }
    }
}

/* line 15, ../sass/_page.scss */

/* line 20, ../sass/_page.scss */

/* line 22, ../sass/_page.scss */

/* line 26, ../sass/_page.scss */

/* line 28, ../sass/_page.scss */

/* line 31, ../sass/_page.scss */

/* line 34, ../sass/_page.scss */

/* line 39, ../sass/_page.scss */

/* line 53, ../sass/_page.scss */

.contactPage {
    .pageVisual {
                        //        background: url(../images/pages/contact/visual.jpg) no-repeat center center;
        background-size : cover;
        position        : relative;
        width           : 100%;
        height          : 400px;
        .pageTtl {
                     //            background: url(../images/pages/contact/ttl.png) no-repeat;
            width    : 248px;
            height   : 94px;
            position : absolute;
            top      : 0;
            right    : 0;
            bottom   : 0;
            left     : 0;
            margin   : auto;
        }
    }
    .blk-1 {
        padding-top    : 76px;
        padding-bottom : 100px;
        width          : 990px;
        margin         : auto;
        .contact_nav {
            width  : 990px;
            margin : auto auto 65px;
            ul {
                margin : 0 auto;
                width  : 990px;
                li {
                    width  : 495px;
                    height : 80px;
                    float  : left;
                    a {
                        color              : #aeaeae;
                        width              : 495px;
                        height             : 80px;
                        display            : block;
                        border             : #aeaeae 1px solid;
                        font-weight        : bold;
                        font-size          : 20px;
                        text-align         : center;
                        line-height        : 80px;
                        text-decoration    : none;
                        -moz-transition    : all 0.3s;
                        -o-transition      : all 0.3s;
                        -webkit-transition : all 0.3s;
                        transition         : all 0.3s;
                        &.active {
                            color      : #ffffff;
                            background : #e4007f;
                            border     : 1px solid #e4007f;
                            &:hover {
                                color : #ffffff;
                            }
                        }
                        &:hover {
                            color  : #e4007f;
                            border : 1px solid #e4007f;
                        }
                    }
                }
            }
        }
        .ttl-2 {
                   //            background: url(../images/pages/contact/ttl-2.png) no-repeat;
            width  : 192px;
            height : 64px;
            margin : auto auto 60px;
        }
        .ttl-3 {
                   //            background: url(../images/pages/contact/ttl-3.png) no-repeat;
            width  : 318px;
            height : 66px;
            margin : auto auto 60px;
        }
        p {
            font-size   : 14px;
            line-height : 32px;
            text-align  : center;
            margin      : auto auto 25px;
            &.sml {
                font-size : 12px;
                margin    : auto auto 45px;
                span {
                    color : #e4007f;
                }
            }
        }
        .contact_list {
            width   : 781px;
            display : block;
            margin  : auto;
            dt {
                width               : 218px;
                min-height          : 48px;
                float               : left;
                margin              : 0 0 25px;
                font-size           : 14px;
                color               : #000000;
                font-weight         : bold;
                vertical-align      : middle;
                display             : -webkit-flex;
                                    /* Safari */
                display             : flex;
                                    /* 2 */
                -webkit-align-items : center;
                                    /* Safari */
                align-items         : center;
                span {
                    color : #e4007f;
                }
            }
            dd {
                min-height : 48px;
                margin     : 0 0 25px 218px;
                width      : 563px;
                display    : block;
                overflow   : hidden;
                input {
                    height    : 48px;
                    width     : 395px;
                    font-size : 12px;
                    border    : 1px solid #c7c7c7;
                    padding   : 16px;
                }
                select {
                    height             : 48px;
                    width              : 395px;
                    font-size          : 12px;
                    -webkit-appearance : none;
                    -moz-appearance    : none;
                    appearance         : none;
                                       //                    background: url(../images/pages/contact/arrow.png) no-repeat right;
                    border-radius      : 0;
                    padding            : 16px;
                    border             : 1px solid #c7c7c7;
                    &.sml1 {
                        width : 177px;
                        float : left;
                    }
                    &.sml2 {
                        width : 95px;
                        float : left;
                    }
                }
                span {
                    display     : block;
                    overflow    : hidden;
                    text-align  : center;
                    width       : 50px;
                    height      : 48px;
                    line-height : 48px;
                    float       : left;
                }
                textarea {
                    width     : 560px;
                    height    : 318px;
                    font-size : 12px;
                    border    : 1px solid #c7c7c7;
                    padding   : 16px;
                }
                input:focus, textarea:focus, select:focus {
                    border  : 1px solid #e4007f;
                    outline : 0;
                }
            }
        }
        .contact_txt {
            width   : 781px;
            display : block;
            margin  : auto;
            p {
                padding-left : 218px;
                font-size    : 12px;
                line-height  : 2em;
                margin       : 0 0 30px;
                text-align   : left;
                a {
                    color : #e4007f;
                    &:hover {
                        text-decoration : none;
                    }
                }
            }
            .btn {
                margin-left        : 218px;
                width              : 348px;
                height             : 72px;
                line-height        : 72px;
                border-radius      : 15px;
                border             : 2px solid #e4007f;
                text-align         : center;
                display            : block;
                text-decoration    : none;
                color              : #e4007f;
                font-size          : 20px;
                font-weight        : bold;
                -moz-transition    : all 0.3s;
                -o-transition      : all 0.3s;
                -webkit-transition : all 0.3s;
                transition         : all 0.3s;
                &:hover {
                    background : #e4007f;
                    color      : #ffffff;
                }
            }
        }
    }
}

/* line 59, ../sass/_page.scss */

/* line 71, ../sass/_page.scss */

/* line 76, ../sass/_page.scss */

/* line 79, ../sass/_page.scss */

/* line 82, ../sass/_page.scss */

/* line 86, ../sass/_page.scss */

/* line 98, ../sass/_page.scss */

/* line 102, ../sass/_page.scss */

/* line 106, ../sass/_page.scss */

/* line 115, ../sass/_page.scss */

/* line 121, ../sass/_page.scss */

/* line 128, ../sass/_page.scss */

/* line 133, ../sass/_page.scss */

/* line 136, ../sass/_page.scss */

/* line 142, ../sass/_page.scss */

/* line 146, ../sass/_page.scss */

/* line 159, ../sass/_page.scss */

/* line 163, ../sass/_page.scss */

/* line 169, ../sass/_page.scss */

/* line 176, ../sass/_page.scss */

/* line 187, ../sass/_page.scss */

/* line 191, ../sass/_page.scss */

/* line 196, ../sass/_page.scss */

/* line 205, ../sass/_page.scss */

/* line 213, ../sass/_page.scss */

/* line 222, ../sass/_page.scss */

/* line 226, ../sass/_page.scss */

/* line 232, ../sass/_page.scss */

/* line 234, ../sass/_page.scss */

/* line 239, ../sass/_page.scss */

/* line 253, ../sass/_page.scss */

/* line 263, ../sass/_page.scss */

.uvPage {
    .pageVisual {
                        //        background: url(../images/pages/uservoice/visual.jpg) no-repeat center center;
        background-size : cover;
        position        : relative;
        width           : 100%;
        height          : 400px;
        .pageTtl {
                     //            background: url(../images/pages/uservoice/ttl.png) no-repeat;
            width    : 330px;
            height   : 94px;
            position : absolute;
            top      : 0;
            right    : 0;
            bottom   : 0;
            left     : 0;
            margin   : auto;
        }
    }
    .blk-1 {
        padding : 90px 0 100px;
        h2 {
            font-size   : 26px;
            color       : #e4007f;
            line-height : 1;
            text-align  : center;
            margin      : auto auto 40px;
            font-weight : bold;
        }
        p {
            font-size      : 14px;
            line-height    : 28px;
            letter-spacing : 0.84px;
            text-align     : center;
        }
        .voice-list {
            width  : 990px;
            margin : 46px auto auto;
            li {
                width   : 470px;
                display : block;
                float   : left;
                margin  : 0 50px 0 0;
                height  : 455px;
                &:nth-of-type(2n) {
                    margin : 0;
                }
                .meta {
                    position : relative;
                    width    : 100%;
                    height   : 28px;
                    margin   : 0 0 20px;
                    h3 {
                        margin : 0;
                    }
                    .ttl-2 {
                               //                        background: url(../images/pages/uservoice/ttl-2.png) no-repeat;
                        width  : 164px;
                        height : 28px;
                    }
                    .ttl-3 {
                               //                        background: url(../images/pages/uservoice/ttl-3.png) no-repeat;
                        width  : 200px;
                        height : 28px;
                    }
                    p {
                        line-height : 1;
                        position    : absolute;
                        right       : 0;
                        bottom      : 0;
                        font-size   : 12px;
                    }
                }
                .thumb {
                    width    : 100%;
                    height   : 264px;
                    display  : block;
                    position : relative;
                    margin   : 0 0 20px;
                    img {
                        display : block;
                        width   : 100%;
                        height  : auto;
                    }
                }
                .txt {
                    .question {
                                     //                        background: url(../images/pages/uservoice/arrow.svg) no-repeat left;
                        padding-left : 32px;
                        font-size    : 16px;
                        font-weight  : bold;
                        color        : #e4007f;
                        margin       : 0 0 12px;
                        display      : block;
                        line-height  : 1;
                    }
                    p {
                        text-align  : left;
                        font-size   : 15px;
                        line-height : 32px;
                    }
                }
            }
        }
    }
}

/* line 269, ../sass/_page.scss */

/* line 281, ../sass/_page.scss */

/* line 283, ../sass/_page.scss */

/* line 291, ../sass/_page.scss */

/* line 297, ../sass/_page.scss */

/* line 300, ../sass/_page.scss */

/* line 306, ../sass/_page.scss */

/* line 309, ../sass/_page.scss */

/* line 314, ../sass/_page.scss */

/* line 317, ../sass/_page.scss */

/* line 323, ../sass/_page.scss */

/* line 328, ../sass/_page.scss */

/* line 336, ../sass/_page.scss */

/* line 342, ../sass/_page.scss */

/* line 349, ../sass/_page.scss */

/* line 359, ../sass/_page.scss */

/* line 369, ../sass/_page.scss */

.userPolicyPage {
    padding-bottom : 100px;
    .pageTtl {
               //        background: url(../images/pages/userpolicy/ttl.png) no-repeat;
        width  : 352px;
        height : 94px;
        margin : 135px auto 45px;
    }
    .blk-1 {
        width   : 990px;
        padding : 50px 0;
        h2 {
            width       : 100%;
            height      : 55px;
            border      : 1px dashed #e4007f;
            line-height : 55px;
            text-align  : center;
            font-size   : 22px;
            font-weight : bold;
            color       : #e4007f;
        }
    }
    .blk-2 {
        width          : 990px;
        margin         : auto;
        padding-bottom : 40px;
        h3 {
            color       : #e4007f;
            font-size   : 20px;
            font-weight : bold;
            margin      : 0 0 30px;
            line-height : 1;
        }
        h4 {
            font-size   : 14px;
            font-weight : bold;
            margin      : 0 0 10px;
            line-height : 1;
        }
        p {
            font-size   : 12px;
            line-height : 24px;
            margin      : 0 0 20px;
            span {
                position       : relative;
                padding-bottom : 5px;
                &:after {
                    content    : '';
                    height     : 1px;
                    width      : 100%;
                    background : #000000;
                    left       : 0;
                    bottom     : 0;
                    position   : absolute;
                }
            }
        }
        ol {
            list-style-position : outside;
            margin-left         : 14px;
            li {
                list-style   : decimal;
                line-height  : 24px;
                font-size    : 12px;
                padding-left : 10px;
                span {
                    position       : relative;
                    padding-bottom : 5px;
                    &:after {
                        content    : '';
                        height     : 1px;
                        width      : 100%;
                        background : #000000;
                        left       : 0;
                        bottom     : 0;
                        position   : absolute;
                    }
                }
            }
        }
        .mb1 {
            margin-bottom : 23px !important;
        }
        .mb2 {
            margin-bottom : 30px !important;
        }
    }
    .blk-3 {
        width  : 990px;
        margin : auto;
        h2 {
            width       : 100%;
            height      : 55px;
            border      : 1px dashed #e4007f;
            line-height : 55px;
            text-align  : center;
            font-size   : 22px;
            font-weight : bold;
            color       : #e4007f;
            margin      : auto auto 45px;
        }
        h3 {
            font-size   : 14px;
            font-weight : bold;
            line-height : 24px;
            color       : #e4007f;
        }
        p {
            font-size     : 12px;
            line-height   : 24px;
            margin-bottom : 26px;
        }
    }
}

/* line 371, ../sass/_page.scss */

/* line 377, ../sass/_page.scss */

/* line 380, ../sass/_page.scss */

/* line 391, ../sass/_page.scss */

/* line 395, ../sass/_page.scss */

/* line 402, ../sass/_page.scss */

/* line 408, ../sass/_page.scss */

/* line 412, ../sass/_page.scss */

/* line 415, ../sass/_page.scss */

/* line 426, ../sass/_page.scss */

/* line 429, ../sass/_page.scss */

/* line 434, ../sass/_page.scss */

/* line 437, ../sass/_page.scss */

/* line 449, ../sass/_page.scss */

/* line 452, ../sass/_page.scss */

/* line 457, ../sass/_page.scss */

/* line 460, ../sass/_page.scss */

/* line 472, ../sass/_page.scss */

/* line 478, ../sass/_page.scss */

/* line 488, ../sass/_page.scss */

.companyPage {
    .pageVisual {
                        //        background: url(../images/pages/company/visual.jpg) no-repeat center center;
        background-size : cover;
        position        : relative;
        width           : 100%;
        height          : 400px;
        .pageTtl {
                     //            background: url(../images/pages/company/ttl.png) no-repeat;
            width    : 440px;
            height   : 104px;
            position : absolute;
            top      : 0;
            right    : 0;
            bottom   : 0;
            left     : 0;
            margin   : auto;
        }
    }
    .blk-1 {
        padding : 85px 0 70px;
        .box-1 {
            width  : 990px;
            margin : auto;
            dl {
                width      : 990px;
                display    : block;
                background : #ffffff;
                dt {
                    padding             : 20px 18px;
                    width               : 218px;
                    float               : left;
                    font-size           : 14px;
                    display             : -webkit-flex;
                                        /* Safari */
                    display             : flex;
                                        /* 2 */
                    -webkit-align-items : center;
                                        /* Safari */
                    align-items         : center;
                    &:nth-of-type(1), &:nth-of-type(2n+1) {
                        background : #ececec;
                    }
                }
                dd {
                    margin-left : 218px;
                    padding     : 20px 18px;
                    font-size   : 14px;
                    &:nth-of-type(2n+1) {
                        background : #ececec;
                    }
                    p {
                        line-height : 26px;
                    }
                }
            }
        }
    }
    .blk-2 {
        margin         : auto;
        width          : 990px;
        padding-bottom : 100px;
        .ttl-2 {
                   //            background: url(../images/pages/company/ttl-2.png);
            width  : 264px;
            height : 66px;
            margin : auto auto 70px;
        }
        .history {
            width : 990px;
            .left {
                padding-left : 20px;
                             //                background: url(../images/pages/company/left_back.png) repeat-y 62px center;
                width        : 174px;
                font-size    : 15px;
                font-weight  : bold;
                height       : 70px;
                float        : left;
                &.block1 {
                    height : 90px;
                }
                &.block2 {
                    padding-top : 36px;
                    height      : 103px;
                }
                &.block3 {
                    padding-top : 36px;
                    height      : 127px;
                }
                &.block4 {
                    padding-top : 36px;
                    height      : 150px;
                }
                &.last {
                    padding-top : 40px;
                    height      : 65px;
                    border      : none;
                }
                p {
                    padding    : 9px 0;
                    background : #ffffff;
                }
                span {
                    display     : block;
                    font-size   : 14px;
                    color       : #959595;
                    font-weight : normal;
                    margin-top  : 5px;
                }
            }
            .right {
                margin-left   : 174px;
                display       : block;
                width         : calc(990px - 174px);
                height        : 70px;
                border-bottom : 1px dotted #000000;
                &.block1 {
                    height : 90px;
                }
                &.block2 {
                    height      : 103px;
                    padding-top : 42px;
                }
                &.block3 {
                    padding-top : 42px;
                    height      : 127px;
                }
                &.block4 {
                    padding-top : 42px;
                    height      : 150px;
                }
                &.last {
                    padding-top : 48px;
                    height      : 65px;
                    border      : none;
                }
                p {
                    font-size   : 14px;
                    line-height : 24px;
                    span {
                        color       : #e4007f;
                        font-weight : bold;
                    }
                }
            }
        }
    }
}

/* line 494, ../sass/_page.scss */

/* line 506, ../sass/_page.scss */

/* line 508, ../sass/_page.scss */

/* line 511, ../sass/_page.scss */

/* line 515, ../sass/_page.scss */

/* line 524, ../sass/_page.scss */

/* line 527, ../sass/_page.scss */

/* line 532, ../sass/_page.scss */

/* line 535, ../sass/_page.scss */

/* line 539, ../sass/_page.scss */

/* line 546, ../sass/_page.scss */

/* line 550, ../sass/_page.scss */

/* line 556, ../sass/_page.scss */

/* line 559, ../sass/_page.scss */

/* line 567, ../sass/_page.scss */

/* line 570, ../sass/_page.scss */

/* line 575, ../sass/_page.scss */

/* line 579, ../sass/_page.scss */

/* line 583, ../sass/_page.scss */

/* line 589, ../sass/_page.scss */

/* line 593, ../sass/_page.scss */

/* line 601, ../sass/_page.scss */

/* line 607, ../sass/_page.scss */

/* line 610, ../sass/_page.scss */

/* line 614, ../sass/_page.scss */

/* line 618, ../sass/_page.scss */

/* line 622, ../sass/_page.scss */

/* line 628, ../sass/_page.scss */

/* line 631, ../sass/_page.scss */

/* line 642, ../sass/_page.scss */

.conceptPage {
    .pageVisual {
                        //        background: url(../images/pages/about/visual.jpg) no-repeat center center;
        background-size : cover;
        position        : relative;
        width           : 100%;
        height          : 400px;
        .main-visual {
                            //            background: url(../images/pages/about/visual.jpg) no-repeat center center;
            background-size : cover;
            width           : 100%;
            height          : 400px;
            position        : fixed;
        }
        .pageTtl {
                     //            background: url(../images/pages/about/ttl.png) no-repeat;
            width    : 198px;
            height   : 94px;
            position : absolute;
            top      : 0;
            right    : 0;
            bottom   : 0;
            left     : 0;
            margin   : auto;
        }
    }
    .blk-1 {
        width          : 990px;
        margin         : auto;
        padding-top    : 85px;
        padding-bottom : 80px;
        .ttl-1 {
                   //            background: url(../images/pages/about/concept-ttl-1.png) no-repeat;
            width  : 180px;
            height : 68px;
            margin : auto auto 65px;
        }
        .ttl-2 {
                   //            background: url(../images/pages/about/concept-ttl-1-2.png) no-repeat;
            width  : 336px;
            height : 80px;
            margin : auto auto 40px;
        }
        .concept-list {
            width  : 990px;
            margin : auto;
            li {
                float         : left;
                width         : 325px;
                display       : block;
                              //                background: url(../images/pages/about/cross.png) no-repeat right 100px;
                margin-right  : 40px;
                padding-right : 85px;
                &:first-child {
                    padding-right : 75px;
                }
                &:last-child {
                    padding          : 0;
                    margin           : 0;
                    width            : 240px;
                    background       : transparent;
                    background-image : none;
                }
                .thumb {
                    width  : 100%;
                    margin : auto auto 20px;
                    img {
                        display : block;
                        margin  : auto;
                    }
                }
                .txt {
                    width : 100%;
                    h3 {
                        font-size   : 22px;
                        color       : #e4007f;
                        text-align  : center;
                        font-weight : bold;
                        margin      : auto auto 20px;
                        span {
                            font-weight : normal;
                            display     : block;
                            margin-top  : 10px;
                            font-size   : 14px;
                            color       : #000000;
                        }
                    }
                    p {
                        font-size      : 13px;
                        text-align     : center;
                        line-height    : 22px;
                        letter-spacing : -0.01em;
                    }
                }
            }
        }
    }
    .blk-2 {
        background : #f9f0e3;
        padding    : 80px 0 30px;
        text-align : center;
        .ttl-3 {
                   //            background: url(../images/pages/about/concept-ttl-2.png) no-repeat;
            width  : 160px;
            height : 66px;
            margin : auto auto 50px;
        }
        p {
            font-size      : 14px;
            line-height    : 28px;
            margin         : 0 0 40px;
            letter-spacing : -0.01em;
            &.txt-1 {
                font-size   : 27px;
                font-weight : bold;
                line-height : 1;
                margin      : 0 0 30px;
                span {
                    color : #e4007f;
                }
            }
        }
        .icon-list {
            width  : 800px;
            margin : auto;
            li {
                width       : 150px;
                display     : block;
                float       : left;
                margin      : 0 64px 35px 0;
                padding-top : 173px;
                text-align  : center;
                font-size   : 14px;
                font-weight : bold;
                &.last {
                    margin : 0 0 35px 0;
                }
                &.icon1 {
                    //                    background: url(../images/pages/about/icon-1.png) no-repeat;
                }
                &.icon2 {
                    //                    background: url(../images/pages/about/icon-2.png) no-repeat;
                }
                &.icon3 {
                    //                    background: url(../images/pages/about/icon-3.png) no-repeat;
                }
                &.icon4 {
                    //                    background: url(../images/pages/about/icon-4.png) no-repeat;
                }
                &.icon5 {
                    //                    background: url(../images/pages/about/icon-5.png) no-repeat;
                }
                &.icon6 {
                    //                    background: url(../images/pages/about/icon-6.png) no-repeat;
                }
                &.icon7 {
                    //                    background: url(../images/pages/about/icon-7.png) no-repeat;
                }
                &.icon8 {
                    //                    background: url(../images/pages/about/icon-8.png) no-repeat;
                }
            }
        }
    }
    .blk-3 {
        padding : 85px 0;
        .visual {
                            //            background: url(../images/pages/about/concept-visual-1.jpg) no-repeat;
            background-size : cover;
            width           : 100%;
            height          : 460px;
            display         : block;
            margin          : auto auto 45px;
        }
        .ttl-4 {
                   //            background: url(../images/pages/about/concept-ttl-3.png) no-repeat;
            width  : 220px;
            height : 66px;
            margin : auto auto 40px;
        }
        p {
            font-size   : 14px;
            line-height : 32px;
            text-align  : center;
            &.txt-1 {
                font-size   : 25px;
                line-height : 1;
                font-weight : bold;
                margin      : auto auto 30px;
                span {
                    color : #e4007f;
                }
            }
        }
    }
    .blk-4 {
        background : #f9f2f6;
        padding    : 85px 0;
        .ttl-5 {
                   //            background: url(../images/pages/about/concept-ttl-4.png) no-repeat;
            width  : 252px;
            height : 66px;
            margin : auto auto 40px;
        }
        p {
            font-size   : 14px;
            line-height : 32px;
            margin      : auto auto 40px;
            text-align  : center;
        }
        .visual {
            display : block;
                    //            background: url(../images/pages/about/concept-img-1.png) no-repeat;
            width   : 642px;
            height  : 602px;
            margin  : auto;
        }
    }
}

/* line 648, ../sass/_page.scss */

/* line 655, ../sass/_page.scss */

/* line 667, ../sass/_page.scss */

/* line 672, ../sass/_page.scss */

/* line 678, ../sass/_page.scss */

/* line 684, ../sass/_page.scss */

/* line 687, ../sass/_page.scss */

/* line 694, ../sass/_page.scss */

/* line 697, ../sass/_page.scss */

/* line 704, ../sass/_page.scss */

/* line 707, ../sass/_page.scss */

/* line 712, ../sass/_page.scss */

/* line 714, ../sass/_page.scss */

/* line 720, ../sass/_page.scss */

/* line 728, ../sass/_page.scss */

/* line 738, ../sass/_page.scss */

/* line 742, ../sass/_page.scss */

/* line 748, ../sass/_page.scss */

/* line 753, ../sass/_page.scss */

/* line 758, ../sass/_page.scss */

/* line 763, ../sass/_page.scss */

/* line 766, ../sass/_page.scss */

/* line 776, ../sass/_page.scss */

/* line 779, ../sass/_page.scss */

/* line 782, ../sass/_page.scss */

/* line 785, ../sass/_page.scss */

/* line 788, ../sass/_page.scss */

/* line 791, ../sass/_page.scss */

/* line 794, ../sass/_page.scss */

/* line 797, ../sass/_page.scss */

/* line 800, ../sass/_page.scss */

/* line 807, ../sass/_page.scss */

/* line 809, ../sass/_page.scss */

/* line 818, ../sass/_page.scss */

/* line 824, ../sass/_page.scss */

/* line 828, ../sass/_page.scss */

/* line 833, ../sass/_page.scss */

/* line 839, ../sass/_page.scss */

/* line 842, ../sass/_page.scss */

/* line 848, ../sass/_page.scss */

/* line 855, ../sass/_page.scss */

/* line 865, ../sass/_page.scss */

.messagePage {
    padding-bottom : 100px;
    .pageVisual {
        width    : 100%;
        height   : 400px;
        position : relative;
        .main-visual {
                            //            background: url(../images/pages/about/visual.jpg) no-repeat center center;
            background-size : cover;
            width           : 100%;
            height          : 400px;
            position        : fixed;
        }
        .pageTtl {
                     //            background: url(../images/pages/about/ttl.png) no-repeat;
            width    : 198px;
            height   : 94px;
            position : absolute;
            top      : 0;
            right    : 0;
            bottom   : 0;
            left     : 0;
            margin   : auto;
        }
    }
    .blk-1 {
        padding-top : 85px;
        .ttl-1 {
                   //            background: url(../images/pages/about/message-ttl-1.png) no-repeat;
            width  : 222px;
            height : 66px;
            margin : auto auto 60px;
        }
    }
    .blk-2 {
                            //        background: url(../images/pages/about/message-photo.jpg) no-repeat 70%;
        width               : 100%;
        height              : 689px;
        background-size     : cover;
        position            : relative;
        display             : -webkit-flex;
                            /* Safari */
        display             : flex;
                            /* 2 */
        -webkit-align-items : center;
                            /* Safari */
        align-items         : center;
        min-width           : 990px;
        margin              : auto auto 95px;
        .txt {
            width         : 990px;
            margin        : auto;
            padding-right : calc(990px - 422px);
            display       : block;
            position      : relative;
            .logo {
                       //                background: url(../images/pages/about/message-logo.png) no-repeat;
                width  : 126px;
                height : 28px;
                margin : 0 0 22px;
            }
            p {
                font-size   : 13px;
                line-height : 24px;
                margin      : 0 0 25px;
                color       : #1d1d1d;
            }
        }
    }
    .blk-3 {
        .name {
            width  : 480px;
            margin : auto auto 50px;
            .left {
                width         : 165px;
                display       : block;
                float         : left;
                text-align    : center;
                padding-right : 32px;
                border-right  : 1px solid #000000;
                height        : 115px;
                padding-top   : 30px;
                h3 {
                    color          : #1d1d1d;
                    font-size      : 30px;
                    letter-spacing : 0.6px;
                    font-weight    : bold;
                }
                span {
                    display        : block;
                    font-family    : Arial;
                    font-weight    : bold;
                    font-size      : 12px;
                    letter-spacing : 0.72px;
                    color          : #e4007f;
                    margin-top     : 7px;
                }
            }
            .right {
                float   : right;
                width   : 315px;
                height  : 115px;
                display : block;
                padding : 3px 0 0 32px;
                h3 {
                    font-family    : Arial;
                    font-weight    : bold;
                    font-size      : 16px;
                    line-height    : 26.17px;
                    letter-spacing : 0.96px;
                    margin         : 0;
                }
                p {
                    font-size   : 14px;
                    line-height : 26.25px;
                    color       : #1d1d1d;
                }
            }
        }
        .txt {
            width   : 990px;
            display : block;
            margin  : auto;
            h2 {
                width       : 100%;
                height      : 110px;
                border      : 1px dashed #e4007f;
                text-align  : center;
                font-size   : 20px;
                font-weight : bold;
                color       : #e4007f;
                padding     : 32px 0 0 0;
                margin      : 0 0 20px;
                span {
                    font-family    : Arial;
                    font-size      : 14px;
                    letter-spacing : 1.12px;
                    color          : #1d1d1d;
                    display        : block;
                    margin         : 8px auto auto;
                    font-weight    : normal;
                }
            }
            h3 {
                font-size   : 15px;
                font-weight : bold;
                color       : #e4007f;
                margin      : 0 0 12px;
                line-height : 1;
            }
            p {
                font-size   : 13px;
                line-height : 26px;
                margin      : 0 0 20px;
            }
            .left {
                width        : 455px;
                display      : block;
                float        : left;
                margin-right : 80px;
                .box {
                    display        : block;
                    padding-bottom : 30px;
                    margin-bottom  : 54px;
                    border-bottom  : 1px dashed #000000;
                    &.last {
                        border : none;
                    }
                }
            }
            .right {
                width    : 455px;
                display  : block;
                overflow : hidden;
                float    : right;
            }
        }
    }
}

/* line 867, ../sass/_page.scss */

/* line 871, ../sass/_page.scss */

/* line 878, ../sass/_page.scss */

/* line 890, ../sass/_page.scss */

/* line 892, ../sass/_page.scss */

/* line 899, ../sass/_page.scss */

/* line 911, ../sass/_page.scss */

/* line 917, ../sass/_page.scss */

/* line 923, ../sass/_page.scss */

/* line 932, ../sass/_page.scss */

/* line 935, ../sass/_page.scss */

/* line 944, ../sass/_page.scss */

/* line 950, ../sass/_page.scss */

/* line 960, ../sass/_page.scss */

/* line 966, ../sass/_page.scss */

/* line 974, ../sass/_page.scss */

/* line 981, ../sass/_page.scss */

/* line 985, ../sass/_page.scss */

/* line 995, ../sass/_page.scss */

/* line 1005, ../sass/_page.scss */

/* line 1012, ../sass/_page.scss */

/* line 1017, ../sass/_page.scss */

/* line 1022, ../sass/_page.scss */

/* line 1027, ../sass/_page.scss */

/* line 1033, ../sass/_page.scss */

/* line 1043, ../sass/_page.scss */

.wmPage {
    padding-bottom : 100px;
    .pageVisual {
        width    : 100%;
        height   : 400px;
        position : relative;
        .main-visual {
                            //            background: url(../images/pages/about/visual.jpg) no-repeat center center;
            background-size : cover;
            width           : 100%;
            height          : 400px;
            position        : fixed;
        }
        .pageTtl {
                     //            background: url(../images/pages/about/ttl.png) no-repeat;
            width    : 198px;
            height   : 94px;
            position : absolute;
            top      : 0;
            right    : 0;
            bottom   : 0;
            left     : 0;
            margin   : auto;
        }
    }
    .blk-1 {
        padding-top : 85px;
        margin      : auto;
        .ttl-1 {
                   //            background: url(../images/pages/about/webadmission-ttl-1.png) no-repeat;
            width  : 260px;
            height : 64px;
            margin : auto auto 57px;
        }
        p {
            font-size   : 14px;
            line-height : 28px;
            text-align  : center;
        }
        .wm_ttl {
            padding       : 25px;
            border-top    : 2px solid #e4007f;
            border-bottom : 2px solid #e4007f;
            font-size     : 24px;
            font-weight   : bold;
            color         : #e4007f;
            text-align    : center;
            margin        : auto auto 40px;
            width         : 990px;
        }
        .wm_list {
            width    : 1005px;
            position : relative;
            margin   : auto auto 55px;
            left     : -9px;
            display  : block;
            li {
                width         : 342px;
                display       : block;
                float         : left;
                margin-right  : 14px;
                              //                background: url(../images/pages/about/webadmission-arrow.png) no-repeat right 115px;
                padding-right : 50px;
                &:last-child {
                    width         : 292px;
                    padding-right : 0;
                    margin        : 0;
                    background    : transparent;
                }
                .thumb {
                    width  : 100%;
                    margin : auto auto 20px;
                    img {
                        display : block;
                        width   : 100%;
                        height  : auto;
                    }
                }
                .txt {
                    padding-left : 12px;
                    p {
                        font-size   : 13px;
                        line-height : 26px;
                        text-align  : left;
                    }
                }
            }
        }
        .wm_icon_list {
            width  : 990px;
            margin : 65px auto 65px;
            li {
                padding-top : 190px;
                width       : 170px;
                float       : left;
                margin      : 0 35px 0 0;
                line-height : 22px;
                font-size   : 13px;
                text-align  : center;
                font-weight : bold;
                span {
                    color : #e4007f;
                }
                &:last-child {
                    margin : 0;
                }
                &.icon1 {
                    //                    background: url(../images/pages/about/webadmission-icon-1.png) no-repeat;
                }
                &.icon2 {
                    //                    background: url(../images/pages/about/webadmission-icon-2.png) no-repeat;
                }
                &.icon3 {
                    //                    background: url(../images/pages/about/webadmission-icon-3.png) no-repeat;
                }
                &.icon4 {
                    //                    background: url(../images/pages/about/webadmission-icon-4.png) no-repeat;
                }
                &.icon5 {
                    //                    background: url(../images/pages/about/webadmission-icon-5.png) no-repeat;
                }
            }
        }
        .box-1 {
            width      : 990px;
            border     : 2px solid #e4007f;
            padding    : 32px 32px 24px;
            text-align : center;
            margin     : auto auto 30px;
            h3 {
                font-weight : bold;
                font-size   : 18px;
                margin      : auto auto 15px;
                line-height : 1;
                span {
                    color : #e4007f;
                }
            }
            p {
                font-size   : 14px;
                line-height : 26px;
                a {
                    text-decoration : underline;
                    color           : #e4007f;
                    &:hover {
                        text-decoration : none;
                    }
                }
            }
        }
        .box-2 {
            margin     : auto;
            width      : 990px;
            border     : 1px dashed #000000;
            padding    : 18px 26px 18px;
            text-align : center;
            p {
                font-size   : 12px;
                line-height : 26px;
                a {
                    text-decoration : underline;
                    color           : #e4007f;
                    &:hover {
                        text-decoration : none;
                    }
                }
            }
        }
    }
    .blk-2 {
                            //        background: url(../images/pages/about/message-photo.jpg) no-repeat 70%;
        width               : 100%;
        height              : 689px;
        background-size     : cover;
        position            : relative;
        display             : -webkit-flex;
                            /* Safari */
        display             : flex;
                            /* 2 */
        -webkit-align-items : center;
                            /* Safari */
        align-items         : center;
        min-width           : 990px;
        margin              : auto auto 95px;
        .txt {
            width         : 990px;
            margin        : auto;
            padding-right : calc(990px - 422px);
            display       : block;
            position      : relative;
            .logo {
                       //                background: url(../images/pages/about/message-logo.png) no-repeat;
                width  : 126px;
                height : 28px;
                margin : 0 0 22px;
            }
            p {
                font-size   : 13px;
                line-height : 24px;
                margin      : 0 0 25px;
                color       : #1d1d1d;
            }
        }
    }
    .blk-3 {
        .name {
            width  : 480px;
            margin : auto auto 50px;
            .left {
                width         : 165px;
                display       : block;
                float         : left;
                text-align    : center;
                padding-right : 32px;
                border-right  : 1px solid #000000;
                height        : 115px;
                padding-top   : 30px;
                h3 {
                    color          : #1d1d1d;
                    font-size      : 30px;
                    letter-spacing : 0.6px;
                    font-weight    : bold;
                }
                span {
                    display        : block;
                    font-family    : Arial;
                    font-weight    : bold;
                    font-size      : 12px;
                    letter-spacing : 0.72px;
                    color          : #e4007f;
                    margin-top     : 7px;
                }
            }
            .right {
                float   : right;
                width   : 315px;
                height  : 115px;
                display : block;
                padding : 3px 0 0 32px;
                h3 {
                    font-family    : Arial;
                    font-weight    : bold;
                    font-size      : 16px;
                    line-height    : 26.17px;
                    letter-spacing : 0.96px;
                    margin         : 0;
                }
                p {
                    font-size   : 14px;
                    line-height : 26.25px;
                    color       : #1d1d1d;
                }
            }
        }
        .txt {
            width   : 990px;
            display : block;
            margin  : auto;
            h2 {
                width       : 100%;
                height      : 110px;
                border      : 1px dashed #e4007f;
                text-align  : center;
                font-size   : 20px;
                font-weight : bold;
                color       : #e4007f;
                padding     : 32px 0 0 0;
                margin      : 0 0 20px;
                span {
                    font-family    : Arial;
                    font-size      : 14px;
                    letter-spacing : 1.12px;
                    color          : #1d1d1d;
                    display        : block;
                    margin         : 8px auto auto;
                    font-weight    : normal;
                }
            }
            h3 {
                font-size   : 15px;
                font-weight : bold;
                color       : #e4007f;
                margin      : 0 0 12px;
                line-height : 1;
            }
            p {
                font-size   : 13px;
                line-height : 26px;
                margin      : 0 0 20px;
            }
            .left {
                width        : 455px;
                display      : block;
                float        : left;
                margin-right : 80px;
                .box {
                    display        : block;
                    padding-bottom : 30px;
                    margin-bottom  : 54px;
                    border-bottom  : 1px dashed #000000;
                    &.last {
                        border : none;
                    }
                }
            }
            .right {
                width    : 455px;
                display  : block;
                overflow : hidden;
                float    : right;
            }
        }
    }
}

/* line 1045, ../sass/_page.scss */

/* line 1049, ../sass/_page.scss */

/* line 1056, ../sass/_page.scss */

/* line 1069, ../sass/_page.scss */

/* line 1072, ../sass/_page.scss */

/* line 1078, ../sass/_page.scss */

/* line 1084, ../sass/_page.scss */

/* line 1096, ../sass/_page.scss */

/* line 1102, ../sass/_page.scss */

/* line 1109, ../sass/_page.scss */

/* line 1116, ../sass/_page.scss */

/* line 1119, ../sass/_page.scss */

/* line 1126, ../sass/_page.scss */

/* line 1128, ../sass/_page.scss */

/* line 1136, ../sass/_page.scss */

/* line 1139, ../sass/_page.scss */

/* line 1148, ../sass/_page.scss */

/* line 1151, ../sass/_page.scss */

/* line 1154, ../sass/_page.scss */

/* line 1157, ../sass/_page.scss */

/* line 1160, ../sass/_page.scss */

/* line 1163, ../sass/_page.scss */

/* line 1166, ../sass/_page.scss */

/* line 1171, ../sass/_page.scss */

/* line 1177, ../sass/_page.scss */

/* line 1182, ../sass/_page.scss */

/* line 1186, ../sass/_page.scss */

/* line 1189, ../sass/_page.scss */

/* line 1192, ../sass/_page.scss */

/* line 1198, ../sass/_page.scss */

/* line 1204, ../sass/_page.scss */

/* line 1207, ../sass/_page.scss */

/* line 1210, ../sass/_page.scss */

/* line 1217, ../sass/_page.scss */

/* line 1229, ../sass/_page.scss */

/* line 1235, ../sass/_page.scss */

/* line 1241, ../sass/_page.scss */

/* line 1250, ../sass/_page.scss */

/* line 1253, ../sass/_page.scss */

/* line 1262, ../sass/_page.scss */

/* line 1268, ../sass/_page.scss */

/* line 1278, ../sass/_page.scss */

/* line 1284, ../sass/_page.scss */

/* line 1292, ../sass/_page.scss */

/* line 1299, ../sass/_page.scss */

/* line 1303, ../sass/_page.scss */

/* line 1313, ../sass/_page.scss */

/* line 1323, ../sass/_page.scss */

/* line 1330, ../sass/_page.scss */

/* line 1335, ../sass/_page.scss */

/* line 1340, ../sass/_page.scss */

/* line 1345, ../sass/_page.scss */

/* line 1351, ../sass/_page.scss */

/* line 19, ../sass/_index.scss */

#header {
    display   : block;
    position  : relative;
    width     : 100%;
    min-width : 1000px;
    top       : 0;
    z-index   : 9999;
}

/* line 30, ../sass/_index.scss */

.sp-to-contact {
    display : none;
}

/* line 34, ../sass/_index.scss */

#main_visual {
    position  : relative;
    width     : 100%;
    min-width : 1080px;
    height    : 100vh;
    display   : block;
    overflow  : hidden;
    margin    : 0;
    .bg {
                        //        background: url(../images/main-visual.jpg) no-repeat center center;
        background-size : cover;
        display         : block;
        position        : fixed;
        top             : 0;
        left            : 0;
        width           : 100vw;
        height          : 108%;
        content         : "";
        z-index         : -1;
    }
    .main-txt {
        position        : absolute;
                        //        background: url(../images/main-visual-txt_2.png) no-repeat;
        width           : 574px;
        height          : 234px;
        background-size : cover;
        top             : 0;
        right           : 0;
        bottom          : 0;
        left            : 0;
        margin          : auto;
    }
    .txt {
        position    : absolute;
        right       : 50px;
        bottom      : 50px;
        color       : #ffffff;
        display     : block;
        text-align  : right;
        font-size   : 11px;
        font-weight : bold;
    }
    .arrow {
                                          //        background: url(../images/arrow.png) no-repeat;
        width                             : 39px;
        height                            : 21px;
        position                          : absolute;
        right                             : 0;
        left                              : 0;
        bottom                            : 55px;
        margin                            : auto;
        transition                        : all .2s linear;
        -webkit-transition                : all .2s linear;
        -moz-transition                   : all .2s linear;
        -webkit-animation-name            : fuwafuwa;
                                          /* fuwafuwaっていうアニメーションをしてね！ */
        -webkit-animation-duration        : 2s;
        -webkit-animation-iteration-count : infinite;
                                          /*-webkit-animation-direction:alternate;*/
        -webkit-animation-timing-function : ease;
        -moz-animation-name               : fuwafuwa;
        -moz-animation-duration           : 2s;
        -moz-animation-iteration-count    : infinite;
                                          /*-moz-animation-direction:alternate;*/
        -moz-animation-timing-function    : ease;
    }
}

/* line 43, ../sass/_index.scss */

/* line 56, ../sass/_index.scss */

/* line 69, ../sass/_index.scss */

/* line 80, ../sass/_index.scss */

/* line 108, ../sass/_index.scss */

.content-block {
    display   : block;
    margin    : 0 auto;
    width     : 100%;
    min-width : 1080px;
}

/* line 116, ../sass/_index.scss */

.index-1 {
    width      : 100%;
    display    : block;
    padding    : 70px 0 40px 0;
    text-align : center;
    position   : relative;
    margin     : 0;
    h2.index-ttl-1 {
                        //        background: url(../images/ttl-1.png) no-repeat;
        width           : 515px;
        height          : 39px;
        background-size : cover;
        margin          : 0 auto 28px;
    }
    .list-container {
        width    : 100%;
        display  : block;
        overflow : hidden;
        margin   : auto auto 40px;
    }
    .photo-list {
        width : 7200px;
        li {
            -webkit-animation : thumbanimation 60s linear infinite;
            -moz-animation    : thumbanimation 60s linear infinite;
            animation         : thumbanimation 60s linear infinite;
            float             : left;
            margin            : 0;
            img {
                display : block;
            }
        }
    }
    p {
        font-size   : 14px;
        line-height : 30px;
        margin      : auto auto 14px;
    }
}

/* line 123, ../sass/_index.scss */

/* line 131, ../sass/_index.scss */

/* line 137, ../sass/_index.scss */

/* line 139, ../sass/_index.scss */

/* line 145, ../sass/_index.scss */

/* line 150, ../sass/_index.scss */

/* line 157, ../sass/_index.scss */

.index-2 {
    padding-bottom : 55px;
    margin         : 0;
    .index-ttl-2 {
                        //        background: url(../images/concept.png) no-repeat;
        width           : 217px;
        height          : 44px;
        background-size : cover;
        margin          : 0 auto 50px;
    }
    .concept-list {
        text-align : center;
        width      : 1080px;
        margin     : auto auto 0;
        li {
            display : inline-block;
            width   : 302px;
            margin  : auto 24px 4px;
            &:nth-of-type(4), &:nth-of-type(5) {
                margin : auto 24px 0;
            }
        }
    }
}

/* line 160, ../sass/_index.scss */

/* line 167, ../sass/_index.scss */

/* line 171, ../sass/_index.scss */

/* line 175, ../sass/_index.scss */

/* line 178, ../sass/_index.scss */

/* line 186, ../sass/_index.scss */

.index-3 {
    margin : 0;
    .to-contact-box {
        width           : 100%;
        height          : 320px;
        display         : block;
        padding         : 0;
        margin          : 0;
                        //        background: url(../images/opening-campaign-back.png) no-repeat center center;
        background-size : cover;
        position        : relative;
        overflow        : hidden;
        .txt-1 {
                            //            background: url(../images/opening-campaign.png) no-repeat;
            background-size : cover;
            width           : 658px;
            height          : 107px;
            position        : absolute;
            top             : 45px;
            right           : 0;
            left            : 0;
            margin          : auto;
            line-height     : 0;
        }
        .to-contact {
                               //            background: url(../images/to-contact.png) no-repeat;
            background-size    : cover;
            width              : 546px;
            height             : 86px;
            position           : absolute;
            bottom             : 45px;
            right              : 0;
            left               : 0;
            margin             : auto;
            transition         : all .1s linear;
            -webkit-transition : all .1s linear;
            -moz-transition    : all .1s linear;
            &:hover {
                opacity : .8;
            }
        }
    }
}

/* line 188, ../sass/_index.scss */

/* line 198, ../sass/_index.scss */

/* line 210, ../sass/_index.scss */

/* line 223, ../sass/_index.scss */

/* line 230, ../sass/_index.scss */

.index-4 {
    margin  : 0;
    padding : 61px 0 70px 0;
    .index-ttl-4 {
                        //        background: url(../images/ttl-2.png) no-repeat;
        background-size : cover;
        width           : 320px;
        height          : 45px;
        line-height     : 1;
        margin          : auto auto 50px;
    }
    .system-list {
        width   : 902px;
        height  : auto;
        display : block;
        margin  : auto;
        li {
            &.list-1 {
                       //                background: url(../images/system-1.png) no-repeat;
                width  : 901px;
                height : 369px;
                margin : 0 0 17px;
            }
            &.list-2 {
                       //                background: url(../images/system-2.png) no-repeat;
                width  : 901px;
                height : 316px;
                margin : 0 0 35px;
            }
            &.list-3 {
                       //                background: url(../images/system-3.png) no-repeat;
                width  : 900px;
                height : 369px;
                margin : 0 0 33px;
            }
            &.list-4 {
                       //                background: url(../images/system-4.png) no-repeat;
                width  : 902px;
                height : 376px;
                margin : 0;
            }
        }
    }
}

/* line 233, ../sass/_index.scss */

/* line 241, ../sass/_index.scss */

/* line 247, ../sass/_index.scss */

/* line 254, ../sass/_index.scss */

/* line 261, ../sass/_index.scss */

/* line 268, ../sass/_index.scss */

/* line 278, ../sass/_index.scss */

.photo-bg {
    width                 : 100%;
    min-width             : 1080px;
    height                : 500px;
    display               : block;
    background            : transparent;
    background            : transparent;
    background-size       : cover;
    background-attachment : fixed;
                          /*.bg {
                          background: url(../images/photo-5.png) no-repeat center center;
                          background-size: cover;
                          display: block;
                          position: fixed;
                          top: 0;
                          left: 0;
                          width: 100vw;
                          height: 100%;
                          content: "";
                          z-index: -1;
                        }*/
    #pbbg {
        content                 : "";
        display                 : none;
        position                : fixed;
        top                     : 0;
        left                    : 0;
        z-index                 : -1;
        width                   : 100%;
        height                  : 100vh;
                                //        background: url(../images/photo-5.jpg) no-repeat center center;
        -webkit-background-size : cover;
                                /*Android4*/
    }
}

/* line 288, ../sass/_index.scss */

/* line 314, ../sass/_index.scss */

.index-5 {
    margin  : 0;
    padding : 70px 0 0 0;
    .index-ttl-5 {
        line-height : 1;
                    //        background: url(../images/ttl-3.png) no-repeat;
        width       : 187px;
        height      : 42px;
        margin      : auto auto 50px;
    }
    .map-container {
        .top {
            width      : 100%;
            height     : 160px;
            position   : relative;
            background : #e8008a;
            .address {
                         //                background: url(../images/access.png) no-repeat;
                width    : 516px;
                height   : 62px;
                position : absolute;
                top      : 0;
                right    : 0;
                bottom   : 0;
                left     : 76px;
                margin   : auto;
            }
        }
        #map {
            width   : 100%;
            height  : 530px;
            display : block;
        }
        .bottom {
            width      : 100%;
            height     : 70px;
            position   : relative;
            background : #e8008a;
        }
    }
}

/* line 317, ../sass/_index.scss */

/* line 325, ../sass/_index.scss */

/* line 330, ../sass/_index.scss */

/* line 342, ../sass/_index.scss */

/* line 348, ../sass/_index.scss */

/* line 357, ../sass/_index.scss */

.index-6 {
    margin          : 0;
                    //    background: url(../images/contact-back.jpg) no-repeat center center;
    background-size : cover;
    padding         : 70px 0;
    .index-ttl-6 {
                    //        background: url(../images/contact-ttl.png) no-repeat;
        width       : 142px;
        height      : 44px;
        margin      : auto auto 45px;
        line-height : 1;
    }
    .tel {
               //        background: url(../images/tel.png) no-repeat;
        width  : 296px;
        height : 46px;
        margin : auto auto 28px;
    }
    .contact-btn {
        width              : 540px;
        height             : 80px;
        line-height        : 80px;
        font-size          : 26px;
        font-weight        : bold;
        font-family        : 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
        text-decoration    : none;
        color              : #ffffff;
        display            : block;
        overflow           : hidden;
        border             : 1px solid #ffffff;
        text-align         : center;
        margin             : auto auto 45px;
        border-radius      : 3px;
        transition         : all .1s linear;
        -webkit-transition : all .1s linear;
        -moz-transition    : all .1s linear;
        &:hover {
            opacity : .8;
        }
    }
    p {
        &.name {
            font-size   : 16px;
            line-height : 26px;
            color       : #e8008a;
            font-weight : bold;
            text-align  : center;
            margin      : auto auto 10px;
            span {
                font-size       : 14px;
                text-decoration : underline;
            }
        }
        &.c-address {
            font-size   : 12px;
            font-weight : bold;
            color       : #ffffff;
            text-align  : center;
        }
    }
}

/* line 362, ../sass/_index.scss */

/* line 369, ../sass/_index.scss */

/* line 375, ../sass/_index.scss */

/* line 393, ../sass/_index.scss */

/* line 397, ../sass/_index.scss */

/* line 404, ../sass/_index.scss */

/* line 410, ../sass/_index.scss */

/* line 418, ../sass/_index.scss */

.index-7 {
                    //    background: url(../images/products-back.jpg) no-repeat center center;
    background-size : cover;
    width           : 100%;
    height          : 517px;
    padding         : 70px 0;
    h2 {
                        //        background: url(../images/index-ttl-11.png) no-repeat;
        width           : 590px;
        height          : 36px;
        background-size : cover;
        margin          : auto auto 35px;
    }
    .sp-index-7-inner {
        display : none;
    }
    .products-list {
        width         : 590px;
        height        : 63px;
        display       : block;
        background    : #ffffff;
        border-bottom : 5px solid #f2f2f2;
        margin        : auto auto 13px;
        font-size     : 16px;
        color         : #000000;
        line-height   : 1;
        &:nth-of-type(2n) {
            background    : #fffef3;
            border-bottom : #f5f4e9 5px solid;
        }
        &.mb0 {
            margin-bottom : 0;
        }
        .first {
            width        : 147px;
            height       : 58px;
            border-right : 1px dotted #808080;
            line-height  : 61px;
            padding      : 0 20px;
            float        : left;
        }
        .second {
            width        : 176px;
            border-right : 1px dotted #808080;
            height       : 58px;
            line-height  : 58px;
            padding      : 0 20px;
            float        : left;
        }
        .third {
            width       : 267px;
            height      : 58px;
            line-height : 61px;
            padding     : 0 20px;
            float       : left;
        }
    }
}

/* line 424, ../sass/_index.scss */

/* line 431, ../sass/_index.scss */

/* line 435, ../sass/_index.scss */

/* line 445, ../sass/_index.scss */

/* line 449, ../sass/_index.scss */

/* line 452, ../sass/_index.scss */

/* line 460, ../sass/_index.scss */

/* line 468, ../sass/_index.scss */

/* line 479, ../sass/_index.scss */

.index-8 {
    .ttl-area {
        background : #22adde;
        width      : 100vw;
        height     : 124px;
        position   : relative;
        display    : block;
        margin     : auto;
        &:after {
            content          : ' ';
            height           : 0;
            position         : absolute;
            width            : 0;
            border           : 34px solid transparent;
            border-top-color : #22adde;
            top              : 100%;
            left             : 50%;
            margin-left      : -34px;
        }
        h2 {
                     //            background: url(../images/index-ttl-12.png) no-repeat;
            width    : 578px;
            height   : 64px;
            display  : block;
            margin   : auto;
            position : absolute;
            top      : 0;
            right    : 0;
            bottom   : 0;
            left     : 0;
        }
    }
    .inner {
                        //background: url(../images/contact-back.png) no-repeat center center;
        background-size : cover;
        padding-top     : 70px;
        padding-bottom  : 110px;
        p {
            font-size   : 18px;
            color       : #000000;
            margin      : 0 auto 55px;
            line-height : 1;
            text-align  : center;
        }
        dl {
            width   : 820px;
            display : block;
            margin  : auto;
            dt {
                width       : 248px;
                float       : left;
                font-size   : 15px;
                position    : relative;
                padding-top : 13px;
                span {
                    width         : 44px;
                    height        : 25px;
                    background    : #fcee21;
                    border-radius : 5px;
                    padding       : 6px 9px 5px;
                    font-weight   : bold;
                    margin-left   : 13px;
                    line-height   : 1;
                    font-size     : 12px;
                                  /*
                                width:44px;
                                height:25px;
                                background: url(../images/required.png) no-repeat;
                                margin-left: 13px;
                                position: relative;
                                top:7px;
                                display: inline-block !important;
                                */
                }
            }
            dd {
                width    : 327px;
                margin   : 0 0 22px 248px;
                position : relative;
                input {
                    width     : 327px;
                    height    : 49px;
                    border    : 1px solid #cccccc;
                    font-size : 13px;
                    color     : #000000;
                    padding   : 17px 15px;
                }
                textarea {
                    width     : 576px !important;
                    height    : 321px !important;
                    border    : 1px solid #cccccc;
                    font-size : 13px;
                    color     : #000000;
                    padding   : 17px 15px;
                }
                select {
                    -moz-appearance     : none;
                    -webkit-appearance  : none;
                    appearance          : none;
                    border-radius       : 0;
                    border              : 0;
                    margin              : 0;
                    padding             : 0;
                    background          : none transparent;
                    vertical-align      : middle;
                    font-size           : inherit;
                    color               : inherit;
                    box-sizing          : content-box;
                    width               : 214px;
                    height              : 49px;
                    border              : 1px solid #cccccc;
                    font-size           : 13px;
                    color               : #000000;
                    padding             : 0 15px 0 15px;
                    background          : #ffffff;
                                        //background-image: url(../images/select-arrow.png);
                    background-repeat   : no-repeat;
                    background-position : right;
                }
                input[type="text"]:focus, textarea:focus, select:focus {
                    border  : 1px solid #22adde;
                    outline : 0;
                }
                .submit {
                    width         : 307px;
                    height        : 80px;
                    color         : #ffffff;
                    background    : #eb6fa5;
                    border-radius : 5px;
                    position      : relative;
                    box-shadow    : 0 7px 0 #cd448f;
                    margin-top    : 10px;
                    font-size     : 22px;
                    font-weight   : bold;
                    &:hover {
                        box-shadow : none;
                        transform  : translate3d(0, 7px, 0);
                    }
                }
            }
        }
    }
}

/* line 486, ../sass/_index.scss */

/* line 498, ../sass/_index.scss */

/* line 512, ../sass/_index.scss */

/* line 517, ../sass/_index.scss */

/* line 524, ../sass/_index.scss */

/* line 528, ../sass/_index.scss */

/* line 534, ../sass/_index.scss */

/* line 555, ../sass/_index.scss */

/* line 559, ../sass/_index.scss */

/* line 568, ../sass/_index.scss */

/* line 577, ../sass/_index.scss */

/* line 601, ../sass/_index.scss */

/* line 607, ../sass/_index.scss */

/* line 618, ../sass/_index.scss */

/* animation */
@-webkit-keyframes thumbanimation {
    0% {
        transform         : translateX(0);
        -webkit-transform : translateX(0);
        -moz-transform    : translateX(0);
    }

    100% {
        -webkit-transform : translateX(-1400px);
        -moz-transform    : translateX(-1400px);
        transform         : translateX(-1400px);
    }
}
