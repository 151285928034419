$icon-font-path : "../../../node_modules/bootstrap-sass/assets/fonts/bootstrap/";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

@import "valiables";

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position      : relative;
    min-height    : 1px;
    padding-right : 10px;
    padding-left  : 10px;
}

.col-xs-15 {
    width : 20%;
    float : left;
}

@media (min-width : 768px) {
    .col-sm-15 {
        width : 20%;
        float : left;
    }
}

@media (min-width : 992px) {
    .col-md-15 {
        width : 20%;
        float : left;
    }
}

@media (min-width : 1200px) {
    .col-lg-15 {
        width : 20%;
        float : left;
    }
}

body {
    position         : relative;
    background-color : $color_white;
    font-family      : 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
}

a {
    color : $color_a;

    &:hover {
        color           : $color_a;
        text-decoration : none !important;
    }

    &:active,
    &:focus {
        color : $color_a_active;
    }
}

p {
    margin : 0;
}

.nav {
    .open {
        > a,
        > a:hover,
        > a:focus {
            background-color : inherit;
            border-color     : inherit;
        }
    }

    > li {
        a:hover,
        a:focus {
            text-decoration  : none;
            background-color : inherit;
        }
    }
}

.container {
    width : 1200px;
}

.breadcrumb {
    padding          : inherit;
    margin-bottom    : inherit;
    list-style       : inherit;
    background-color : inherit;
    border-radius    : inherit;
}

@mixin btn {
    padding       : 15px 75px;
    border-radius : 20px;
    font-size     : 16px;
    font-weight   : bold;
}

@mixin frame-dots {
    width       : 100%;
    height      : 55px;
    border      : 1px dashed $color_red;
    line-height : 55px;
    text-align  : center;
    font-size   : 22px;
    font-weight : bold;
    color       : $color_red;
}