#pageHeader {
    #glb_nav {
        li {
            .sml_nav {
                background-color : inherit;

                li {
                    margin-bottom       : 1px;
                    background-color    : rgba(228, 0, 127, 0.6);
                    border              : none;
                    transition-duration : 150ms;
                    transition-property : opacity;

                    &:hover {
                        opacity : .8;
                    }

                    &:last-child {
                        margin-bottom : 0;
                    }
                }
            }
        }
    }
}