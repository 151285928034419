#footer {
    .foot_contact {
        background-image : url(../../asset/image/common/foot-contact_visual.jpg);

        .inner {
            .f_c_ttl {
                background-image : url(../../asset/image/common/foot_contact.png);
            }

            .btn {
                span {
                    background-image : url(../../asset/image/common/foot_contact_txt.png);
                }
            }

            p {
                letter-spacing : 1px;
            }
        }
    }

    .foot_main {
        .main_nav {
            .foot_nav {
                li {
                    a {
                        &:hover {
                            color : $color_red;
                        }
                    }

                    .sml_nav {
                        li {
                            a {
                                &:hover {
                                    color : $color_red;
                                }
                            }
                        }
                    }
                }
            }

            .foot_sns {
                li {
                    a {
                        transition-duration : 150ms;
                        transition-property : opacity;

                        &:hover {
                            opacity : .8;
                        }

                        &.fb {
                            background-image : url(../../asset/image/common/foot_fb.png);
                        }

                        &.yt {
                            background-image : url(../../asset/image/common/foot_yt.png);
                        }
                    }
                }
            }
        }

        .sub_nav {
            ul {
                li {
                    a {
                        &:hover {
                            color : $color_red;
                        }
                    }
                }
            }
        }

        .bnr_list_1 {
            li {
                a {
                    transition-duration : 150ms;
                    transition-property : opacify;

                    &:hover {
                        opacity : 0.8;
                    }

                    &.bnr1 {
                        background-image : url(../../asset/image/common/foot_bnr_1.jpg);
                    }
                    &.bnr2 {
                        background-image : url(../../asset/image/common/foot_bnr_2.jpg);
                    }
                    &.bnr3 {
                        background-image : url(../../asset/image/common/foot_bnr_3.jpg);
                    }
                    &.bnr4 {
                        background-image : url(../../asset/image/common/foot_bnr_4.jpg);
                    }
                }
            }
        }

        .bnr_list_2 {
            li {
                a {
                    transition-duration : 150ms;
                    transition-property : opacify;

                    &:hover {
                        opacity : 0.8;
                    }

                    &.bnr5 {
                        background-image : url(../../asset/image/common/foot_bnr_5.jpg);
                    }
                    &.bnr6 {
                        background-image : url(../../asset/image/common/foot_bnr_6.jpg);
                    }
                    &.bnr7 {
                        background-image : url(../../asset/image/common/foot_bnr_7.jpg);
                    }
                }
            }
        }
    }

    .foot_bottom {
        .copyright {
            background-image : url(../../asset/image/common/copyright.png);
        }
    }
}

